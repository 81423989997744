// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start() // had to deactivate as quill was not loading correctly
require("@rails/activestorage").start()
require("channels")

// window.$ = window.jQuery = require("jquery");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import '../src/cleanui/cleanui';
// import '../src/cleanui/menu-left';
// import '../src/cleanui/top-bar';
// import 'jquery';
import 'bootstrap';
import 'stylesheets/application';
import '../src/pages/pages';
import 'select2';
import 'select2/dist/css/select2.css';
import '@fortawesome/fontawesome-free/js/all.min.js';
import {loadStripe} from '@stripe/stripe-js';
import "cocoon-js-vanilla";

window.$ = $;

var initRemoteModalForms = function () {  
  $('a.remote-modal-form').off().on('click', function(){    
    $($(this).data("target")+' .modal-dialog').load($(this).attr("href"), function(){
      $(".modal-dialog .select2").select2({});
    });
  });
}

var initFlashMessages = function () {
  document.querySelectorAll('.notification').forEach(function(notification) {
    $('body').pgNotification({
      style: 'bar',
      message: notification.innerHTML,
      type: notification.dataset.type,
      position: 'top',
      timeout: 5000
    }).show();
  });
}

var initExtraFlashMessagesModal = function () {
  var element =  document.getElementById('flash-extra-content');
  if (typeof(element) != 'undefined' && element != null)
  {
    $("#flash-extra-content").modal('show');
  }
}

var initNewPaymentPage = function () {
  var paymentTypeSelector =  document.getElementById('payment_payment_type');
  if (typeof(paymentTypeSelector) != 'undefined' && paymentTypeSelector != null)
  {
    paymentTypeSelector.addEventListener("change", function() {
      switch(paymentTypeSelector.value) {
      case "bank_transfer":
        document.getElementById("bank-transfer-hint").classList.remove("hide");
        document.getElementById("top-up-hint").classList.add("hide");
        break;
      case "admin_top_up":
        document.getElementById("bank-transfer-hint").classList.add("hide");
        document.getElementById("top-up-hint").classList.remove("hide");
        break;
      default:
    } 
    })
  }
}

window.initQuillEditor = function () {
  var quillContainer = document.getElementsByClassName("quill-editor");
  if (quillContainer != null && quillContainer.length > 0) {
    var editor = new Quill('.quill-editor', {
      theme: 'snow'
    });
    var field = document.getElementById(editor.container.dataset.parentElement);
    editor.on('text-change', function() {
      field.value = editor.root.innerHTML;
    });
  }
}

// using window because this is called elsewhere
window.initLeadsToMoneyComponent = function () {
  var element =  document.getElementById('enrollment_daily_lead_cap');
  if (typeof(element) != 'undefined' && element != null)
  {
    element.addEventListener("change", function() {
      var amount = document.getElementById('enrollment_current_campaign_lead_price').value * element.value;
      document.getElementById('daily_cap_in_money').innerHTML = `€ ${amount.toFixed(2)}`;
      document.getElementById('enrollment_daily_cap').value = amount.toFixed(2);
    })
  }
}

// using window because this is called elsewhere
window.initCRMSelector = function () {
  var element =  document.getElementById('enrollment_crm_type');
  if (typeof(element) != 'undefined' && element != null)
  {
    element.addEventListener("change", function() {
      console.log(element.value);
      switch (element.value) {
        case 'unused':
          document.getElementById('crm_endpoint_field').classList.add("hide");
          document.getElementById('crm_api_key_field').classList.add("hide");
          break;
        case 'generic':
          document.getElementById('crm_endpoint_field').classList.remove("hide");
          document.getElementById('crm_api_key_field').classList.add("hide");
          break;
        case 'nocrm':
          document.getElementById('crm_endpoint_field').classList.remove("hide");
          document.getElementById('crm_api_key_field').classList.remove("hide");
          break;
        default:
          return false;
      }

    })
  }
}

var initCRMSFieldComposer = function (element) {
  [].forEach.call(document.getElementsByClassName("crm-type-selector"), function (el) {
    el.addEventListener("change", function() {
      console.log("changed")
      switch (el.value) {
      case 'predefined':
        el.parentElement.nextSibling.nextSibling.children[0].value = null;
        el.parentElement.nextSibling.nextSibling.children[1].value = null;
        el.parentElement.nextSibling.nextSibling.children[0].classList.remove("hide");
        el.parentElement.nextSibling.nextSibling.children[1].classList.add("hide");
        break;
      case 'lead_data':
        el.parentElement.nextSibling.nextSibling.children[0].value = null;
        el.parentElement.nextSibling.nextSibling.children[1].value = null;
        el.parentElement.nextSibling.nextSibling.children[0].classList.add("hide");
        el.parentElement.nextSibling.nextSibling.children[1].classList.remove("hide");
        break;
      default:
        return false;
      }
    });
  });
}

var initCRMFieldCocoon = function () {
  document.getElementById('links-row').addEventListener("cocoon:after-insert", function(e){
    var elem = jQuery.parseHTML(e.detail[0]);
    var elem_id = $(elem).find("select.crm-type-selector").attr("id");
    console.log(elem_id);
    var true_elem = document.getElementById(elem_id);
    true_elem.addEventListener("change", function() {
      switch (true_elem.value) {
      case 'predefined':
        true_elem.parentElement.nextSibling.nextSibling.children[0].value = null;
        true_elem.parentElement.nextSibling.nextSibling.children[1].value = null;
        true_elem.parentElement.nextSibling.nextSibling.children[0].classList.remove("hide");
        true_elem.parentElement.nextSibling.nextSibling.children[1].classList.add("hide");
        break;
      case 'lead_data':
        true_elem.parentElement.nextSibling.nextSibling.children[0].value = null;
        true_elem.parentElement.nextSibling.nextSibling.children[1].value = null;
        true_elem.parentElement.nextSibling.nextSibling.children[0].classList.add("hide");
        true_elem.parentElement.nextSibling.nextSibling.children[1].classList.remove("hide");
        break;
      default:
        return false;
      }
    });
  }, false);
}

window.initVoluumFieldComposer = function (element) {
  [].forEach.call(document.getElementsByClassName("voluum-type-selector"), function (el) {

    var field_number = el.dataset.fieldNumber;

    var value_field_name = "campaign[voluum_param_" + field_number + "_value]";

    var static_field = document.getElementsByClassName("voluum_param_" + field_number + "_value_static")[0];
    var campaign_field = document.getElementsByClassName("voluum_param_" + field_number + "_value_campaign")[0];
    var lead_field = document.getElementsByClassName("voluum_param_" + field_number + "_value_lead")[0];

    if(static_field.classList.contains("hide")) { static_field.name = null };
    if(campaign_field.classList.contains("hide")) { campaign_field.name = null };
    if(lead_field.classList.contains("hide")) { lead_field.name = null }

    el.addEventListener("change", function() {

      static_field.value = null;
      campaign_field.value = null;
      lead_field.value = null;

      switch (el.value) {
      case 'static':
        static_field.classList.remove("hide");
        static_field.name = value_field_name;
        campaign_field.classList.add("hide");
        campaign_field.name = null;
        lead_field.classList.add("hide");
        lead_field.name = null;
        break;
      case 'campaign_data':
        static_field.classList.add("hide");
        static_field.name = null;
        campaign_field.classList.remove("hide");
        campaign_field.name = value_field_name;
        lead_field.classList.add("hide");
        lead_field.name = null;
        break;
      case 'lead_data':
        static_field.classList.add("hide");
        static_field.name = null;
        campaign_field.classList.add("hide");
        campaign_field.name = null;
        lead_field.classList.remove("hide");
        lead_field.name = value_field_name;
        break;
      default:
        return false;
      }
    });
  });
}

async function runStripeSetupIntent() {
  const stripe = await loadStripe(creds.stripe_pkey);
  var elements = stripe.elements({locale: 'pt'});
  var cardElement = elements.create('card', {
    hidePostalCode: true,
    style: {
      base: {
        lineHeight: "26px",
        fontSize: "16px",
        fontFamily: 'Open Sans, Segoe UI, sans-serif',
      }
    }
  });
  cardElement.mount('#card-element');
  var cardholderName = document.getElementById('account_cardholder_name');
  var cardButton = document.getElementById('setup_intent_submit');
  var cardForm = document.getElementById('card_form');
  var clientSecret = document.getElementById('account_client_secret').value;
  var paymentMethodId = document.getElementById('account_payment_method_id');

  cardForm.addEventListener('submit', function(ev) {
    console.log("called once");
    
    ev.preventDefault();

    var holder_error_container = document.getElementById('cardholder-errors');
    var error_container = document.getElementById('card-errors');

    if(cardholderName.value == ""){
      holder_error_container.innerHTML = "Por favor, preencha o nome presente no cartão.";
      holder_error_container.classList.remove("d-none");
      return false;
    } else {
      holder_error_container.classList.add("d-none");
    }

    stripe.confirmCardSetup(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: cardholderName.value,
          },
        },
      }
    ).then(function(result) {
      if (result.error) {
        error_container.innerHTML = result.error.message;
        error_container.classList.remove("d-none");
      } else {
        error_container.innerHTML = "";
        error_container.classList.add("d-none");
        console.log("success");
        console.log(result);
        paymentMethodId.value = result.setupIntent.payment_method;
        cardForm.submit();
      }
    });
  });
}

$(document).ready(function () {
  initRemoteModalForms();
  initFlashMessages();
  initExtraFlashMessagesModal();
  initNewPaymentPage();
  // $('.toast').toast({
  //   delay: 3000,
  //   autohide: false
  // }).toast('show');


  // $(".select2").select2();
  $.Pages.init();
  
  if($("#card-element").length > 0){
    runStripeSetupIntent();
  }

  $(".conditional-trigger-checkbox").on("change", function() {
    if($(this).is(":checked")){
      $($(this).data("conditional-target")).removeClass("d-none");
    } else {
      $($(this).data("conditional-target")).addClass("d-none");
      $($(this).data("conditional-target") + " #campaign_exclusive_account_id option:selected").prop("selected", false);
    }
  })

  $('[data-toggle="tooltip"]').tooltip();

  $('#enrollment-modal').on('shown.bs.modal', function (e) {
    $(".select2-on-modal").select2();
  })

  $('#crm-config-modal').on('shown.bs.modal', function (e) {
    initCRMSFieldComposer();
    initCRMFieldCocoon();
  })

});
